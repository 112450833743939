<template>
  <div class="layout-terminal-results">
    <div>
      <Results
        :resultsData="racersResults"
        :colors="labelColors"
        :roundLabel="roundLabel"
        v-if="loaded && racersResults"/>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Results from '@/components/Terminal/RacesEventsResultsATerminal';

export default {
  name: 'Terminal',
  components: {
    Results,
  },
  data() {
    return {
      loaded: false,
      racersResults: null,
    };
  },
  computed: {
    ...mapGetters([
      'offer',
      'gameState',
      'labelColors',
      'translations',
    ]),
    roundLabel() {
      return this.translations.general_round;
    },
  },
  watch: {
    gameState(val) {
      this.racersResults = val.previousResults;
      this.loaded = true;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
